<!-- 七星彩标题栏 -->
<template>
  <div class="ssq-header">
    <div class="pos">
      <ol class="pos1">
        当前位置：
      </ol>
      <ol class="pos2">
        <navigation></navigation>
      </ol>
      <div class="clear"></div>
    </div>
    <div class="ssq-title">
      <div class="ssq-title2">
        <ul>
          <li class="rou" v-for="(item, i) in list2" :key="i" :class="{ active1: i == isActive1 }"
            @click="hreflist2(i), one1(i)">
            {{ item.name }}
          </li>
          <li>
            <select style="width: 120px" ref="col">
              <option :value="item.val" v-for="(item, i) in option" :key="i">
                {{ item.name }}
              </option>
            </select>
          </li>
          <button style="margin-left: 15px" type="button" @click="sele()">
            查询
          </button>
          <div class="clear"></div>
        </ul>
      </div>
      <div class="ssq-title1">
        <ul>
          <li class="rou" v-for="(item, i) in list" :key="i" :class="{ active: i == isActive }"
            @click="hreflist(item.to), one(i)">
            {{ item.name }}
          </li>
        
          <div class="clear"></div>
        </ul>
      </div>
    </div>
    <div class="clear"></div>
  </div>
</template>

<script>
  import { mapState, mapMutations, mapActions } from "vuex";
  import axios from "axios";
  import mitt from "@/js/mitt.js";
  import config from '@/utils/config.js'
  //import '../js/font.js' //js文件引入，注意路径
  //import {myfun} from '../js/test.js' //js文件引入，注意路径
  //import Introduction from '@/views/Introduction.vue'
  import navigation from "@/components/common/navigation.vue";
  export default {
    name: "ssq-header",
    components: {
      navigation,
    },
    data() {
      // 数据源
      return {
        list: [
          { name: "基本走势图", to: "/qxc-basic" },
          { name: "一区走势图", to: "/qxc-q1" },
          { name: "二区走势图", to: "/qxc-q2" },
          { name: "三区走势图", to: "/qxc-q3" },
          { name: "四区走势图", to: "/qxc-q4" },
          { name: "五区走势图", to: "/qxc-q5" },
          { name: "六区走势图", to: "/qxc-q6" },
          { name: "七区走势图", to: "/qxc-q7" },
          { name: "和值尾数走势图", to: "/qxc-hew" },
          { name: "跨度走势图", to: "/qxc-kua" },
          { name: "AC值走势图", to: "/qxc-ac" },
        ],
        list2: [
          { name: "近80期走势图" },
          { name: "近100期走势图" },
          { name: "近120期走势图" },
          { name: "近150期走势图" },
        ],
        option: [
          { name: "按年份", val: "volvo" },
           { name: "2023年", val: "2023" },
          { name: "2022年", val: "2022" },
          { name: "2021年", val: "2021" },
          { name: "2020年", val: "2020" },
          { name: "2019年", val: "2019" },
          { name: "2018年", val: "2018" },
          { name: "2017年", val: "2017" },
          { name: "2016年", val: "2016" },
          { name: "2015年", val: "2015" },
          { name: "2014年", val: "2014" },
          { name: "2013年", val: "2013" },
          { name: "2012年", val: "2012" },
          { name: "2011年", val: "2011" },
          { name: "2010年", val: "2010" },
          { name: "2009年", val: "2009" },
          { name: "2008年", val: "2008" },
          { name: "2007年", val: "2007" },
          { name: "2006年", val: "2006" },
          { name: "2005年", val: "2005" },
        ],
        isActive: -1,
        isActive1: -1,
      };
    },
    mounted() {
      mitt.on("qxc", () => {
        this.hreflist2(50);
      });
      this.isActive = sessionStorage.getItem("qxc");
      this.isActive1 = sessionStorage.getItem("qxc1");
    },
    computed: {
      ...mapState(["qxc"]),
    },
    methods: {
      // 函数体部分，js的主要逻辑控制
      ...mapMutations(["mutationqxc"]),
      ...mapActions(["actionqxc"]),

      one(i) {
        this.isActive = i;
        sessionStorage.setItem("qxc", i);
      },
      one1(i) {
        this.isActive1 = i;
        sessionStorage.setItem("qxc1", i);
      },
      hreflist(item) {
        this.$router.push({ path: item });
      },

      hreflist2(i) {
        if (i == 0) {
          i = 80;
        } else if (i == 1) {
          i = 100;
        } else if (i == 2) {
          i = 120;
        } else if (i == 3) {
          i = 150;
        } else {
          i = 50;
        }
        this.$refs.col.value = "volvo";
        if (this.$store.state.qxc == "" || this.$store.state.qxc.length != i) {
          // alert(i)
          axios({
            method: "post",
            url: config.urlcom + "lottery/demo/findj",
            data: {
              'tab': "qxc",
              'zd': "issueno",
              'i': i,
            },
          }).then(
            //this.url是我在上面定义的一个固定的url
            (res) => {
              var data = [];
              var x = res.data.reverse();
              x.forEach((item) => {
                var red = item.number.split(" ");
                red = red.concat(item.refernumber);
                var obj = {
                  expect: item.issueno,
                  red: red,
                };
                data.push(obj);
              });
              this.actionqxc(data);
            }
          );
          // alert('重新请求数据')
        } else {
          //alert('vuex返回数据')
          return this.$store.state.qxc;
        }
      },

      sele() {
        var select = this.$refs.col.value;
        if (select == "volvo") {
          alert("请选择年份！");
        } else {
          this.isActive1 = -1;
          axios({
            method: "post",
            url: config.urlcom + "lottery/demo/findm",
            data: {
              'tab': "qxc",
              'zd': "issueno",
              'value': select,
            },
          }).then(
            //this.url是我在上面定义的一个固定的url
            (res) => {
              var data = [];
              var x = res.data;
              x.forEach((item) => {
                var red = item.number.split(" ");
                red = red.concat(item.refernumber);
                var obj = {
                  expect: item.issueno,
                  red: red,
                };
                data.push(obj);
              });
              this.actionqxc(data);
            }
          );
        }
      },
    },
    props: {},
    // 监听,当路由发生变化的时候执行
    watch: {
      $route: {
        handler: function (val, oldVal) {
          oldVal;
          if (val.href == "/qxc-basic") {
            this.isActive = 0;
          }
        },
        // 深度观察监听
        //deep: true,
      },
    },
  };
</script>

<style scoped>
  /* @import './swiper.css'; */
  /* *{
        border:1px solid red;
    } */
  .pos {
    float: left;
    text-align: left;
    height: 20px;
    width: 100%;
    line-height: 20px;
    /* border:1px solid rgb(14, 13, 13); */
  }

  .pos1 {
    line-height: 20px;
    float: left;
    text-align: left;
  }

  .pos2 {
    float: left;
    width: 50%;
    text-align: left;
    margin-left: -8px;
    margin-top: -5px;
    height: 20px;
  }

  .ssq-title {
    width: 100%;
    float: left;
    border: 1px solid rgb(201, 197, 197);
  }

  .ssq-title ul li {
    margin: 0;
    padding: 0;
    float: left;
    margin-left: 1.5px;
    height: 30px;
    line-height: 30px;
    font-size: 14px;
    background-color: rgb(219, 213, 213);
  }

  .ssq-title1 ul li {
    float: left;
    width: 9%;
    margin-top: 5px;
    margin-left: 1%;
    border-radius: 20px;
  }

  .ssq-title2 ul li {
    float: left;
    width: 9%;
    margin-left: 1%;
    border-radius: 20px;
  }

  .ssq-title select {
    width: 20px;
    height: 28px;
  }

  .rou {
    float: left;
    width: 130px;
  }

  input {
    width: 80%;
    height: 28px;
  }

  button {
    width: 50px;
    float: left;
    margin-top: 3px;
  }

  .ssq-title1 .active {
    background-color: rgb(32, 8, 252);
    color: aliceblue;
    border-radius: 20px;
  }

  .ssq-title2 .active1 {
    background-color: rgb(32, 8, 252);
    color: aliceblue;
    border-radius: 20px;
  }
</style>